<template>
    <div class="wrap">
        <div class="content-container">
            <Breadcrumb class="breadcrumb" />
            <section class="project-content" id="printMe" ref="printRegion" v-if="!!announcement">
                <div class="header">
                    <div class="upper">
                        <div class="title">{{announcement.title}}</div>
                        <!-- <button class="blue-btn">前往报价</button> -->
                    </div>
                    <div class="lower">
                        <span v-if="announcement.cate">分类：{{announcement.cate}}</span>
                        <span>来源：{{announcement.source || '暂无来源信息'}}</span>
                        <span>发布时间：{{announcement.publish_at}}</span>
                        <!-- <span class="print">【打印】</span> -->
                        <button v-print="printObj" class="print">【打印】</button>
                    </div>
                    <div v-if="announcement.summary" class="abstract">
                        {{announcement.summary}}
                    </div>
                </div>
                <div class="content ql-editor" v-html="announcement.content"></div>
            </section>
        </div>
    </div>
</template>
<script>
import {
    getCurrentInstance,
    reactive,
    toRefs
} from 'vue'
import { useRoute } from 'vue-router'
import print from 'vue3-print-nb'
import Breadcrumb from '@/components/Breadcrumb'
export default {
    name: 'project-detail',
    components: {
        Breadcrumb
    },
    directives: {
        print
    },
    setup() {
        const { proxy } = getCurrentInstance()
        const state = reactive({
            announcement: null,
            printObj: {
                id: "printMe",
                popTitle: ''
            }
        })

        const route = useRoute()
        const announcementId = route.query.id
        proxy.$api.article.getArticle(announcementId).then(res => {
            state.announcement = res.data
        })


        return {
            ...toRefs(state),
        }
    }
}
</script>
<style lang="scss" scoped>
    @import "index";
</style>